@use '../Admissions.module.scss';

$remove: #ed6c02;
$shadow: rgba(0,0,0,0.4);

.documentsModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: Admissions.$white;
    width: 50%;
    padding: 1rem;
    overflow: hidden;
    max-height: 80%;
    display: block;
    outline: 0;
}

.modalContent {
    overflow: auto;
    max-height: 75vh;
    padding: 2rem;
}

.mobileModalContent {
    overflow: auto;
    max-height: 75vh;
    padding: 2rem;
}

.title{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.subTitle{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.mobileBackground {
    background: Admissions.$mobile-background;
}

.documentContainer {
    border: 1px solid Admissions.$divider-color;
    padding: 1rem;
    margin-bottom: 1rem;
}

.mobileDocumentContainer {
    border: 1px solid Admissions.$divider-color;
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: Admissions.$white;
}

.documentDownloadIcon {
    cursor: pointer;
    color: Admissions.$green-check; 
}

.documentDeleteButton {
    color: $remove;
    border-color: $remove;
    border-radius: 0px !important;
    cursor: pointer;
}

.documentUploadDeadline {
    font-size: 14px;
    text-align: right; 
}

.documentInformational {
    border: 1px solid Admissions.$divider-color;
    padding: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
}

.mobileDocumentInformational {
    border: 1px solid Admissions.$divider-color;
    padding: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
    background-color: Admissions.$white;
}

.mobileDocumentsClose {
    margin-top: 1rem;
    background-color: Admissions.$white;
}

.finalizeModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: Admissions.$white;
    width: 90%;
    box-sizing: border-box;
    padding: 1rem;
    overflow: auto;
    max-height: 80vh;
    display: block;
    outline: 0;
}

.finalizeModalButtons {
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    display: flex;
    gap: 8px;
    flex-wrap: wrap-reverse;
}

.programsContainer {
    overflow: auto;
    flex-direction: column;
    padding: 1rem;
}

.programsSelector {
    box-shadow: 0px 1px 6px -3px $shadow;
    margin-bottom: 1rem;
    border-radius: 0px;
}

.programsModal {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: Admissions.$white;
    width: 40%,
}

.programsModalButton {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    border-radius: 0; 
}
