.noBorder {
    border-width: 0 !important;
}

.courseEnrollment .MuiDataGrid-columnHeaderTitle {
    font-size: larger;
    font-weight: bolder;
}

.MuiDataGrid-cell {
    display: flex;
    justify-content: center;
}
