.semesterRow {
    background-color: #deebf7;
    &:hover {
        background-color: #c6dbef !important;
    }
}

.disabledRow {
    background-color: #bfbfbf99;
    &:hover {
        background-color: #bfbfbf99 !important;
    }
}
