.userRow {
    background-color: #deebf7;
    &:hover {
        background-color: #c6dbef !important;
    }
}

.admissinRow {
    background-color: #ffffff;
    &:hover {
        background-color: #ffffff !important;
    }
}

.dataStyle {
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.divider {
    margin-top: 0.5rem;
    border: 1px solid #364354;
}
