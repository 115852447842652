.phone-input .special-label {
    top: -10px;
    background-color: #ffffff;
    color: #ffffff;
}

.phone-input-disabled .special-label {
    top: -10px;
    background-color: #f5f5f5;
    color: #989898;
}
