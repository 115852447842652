@use '../Admissions.module.scss';

$helper-text: #899DB3;

.listContainer {
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.expandIcon {
    cursor: pointer;
    color: Admissions.$text-color;
}

.modeIcon {
    color: Admissions.$yellow;
    cursor: pointer;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.applicantProgramContainer {
    border: 1px solid Admissions.$divider-color;
    margin-bottom: 1rem;
    padding: 1rem;
    height: 100%;
}

.applicantProgramButtons {
    border: 1px solid Admissions.$divider-color;
    padding: 0.5rem;
    cursor: pointer;
}

.applicantProgramButtonText {
    font-size: 14px;
    margin-right: 0.5rem;
}

.finalizeButtonContainer {
    border: 1px solid Admissions.$green-check;
    padding: 0.5rem;
    cursor: pointer;
}

.finalizeButton {
    font-size: 14px;
    margin-right: 0.5rem;
    color: Admissions.$green-check;
}

.uploadedText {
    font-size: 12px;
    font-weight: 400;
    color: $helper-text;
}

.rightPanel {
    font-size: 14px;
    font-weight: 400;
    text-align: right;
}

.rightPanelContainer {
    height: 100%;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
}

.mobileBaseContainer {
    flex-direction: column;
    border: 1px solid Admissions.$divider-color;
    margin-bottom: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 100%;
    background-color: Admissions.$white;
    border-radius: 5px;
}

.mobileButton {
    border: 1px solid Admissions.$divider-color;
    padding: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
}

.mobileButtonText {
    font-size: 12px;
    margin-right: 0.5rem;
}

.helper {
    color: $helper-text;
}

.mobileFinalizeButtonContainer {
    border: 1px solid Admissions.$green-check;
    padding: 0.5rem;
    cursor: pointer;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}

.mobileFinalizeButton {
    font-size: 12px;
    margin-right: 0.5rem;
    color: Admissions.$green-check;
}


.mobileRightPanel {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.mobileRightPanelContainer {
    flex-direction: column;
    margin-top: 1rem;
}

