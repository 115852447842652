$text-color: #364354;
$dark-text: #181E25;
$mobile-background: #EFF2F5;
$divider-color: #C1CBD7;
$white: #FFFFFF;
$error-color: #ff6961;
$yellow: #f3ca12;
$green-check: #35A899;

.selectElement {
    background-color: $text-color;
    align-items: center;
    min-height: 4rem;
    gap: 24px;
    cursor: pointer;
    padding-left: 2rem;
    box-shadow: 0px 4px 4px 0px #36435433;
    flex-direction: row;
}

.selectElementTypography {
    color: $white;
    font-weight: 700;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.contentCenter {
    justify-content: center;
    align-items: center;
}

.selectorTypography {
    color: #181E25;
    text-align: center;
}

.flex {
    display: flex;
    flex-direction: column;
}

.bold {
    font-weight: 900;
}

.bolder {
    font-weight: 700;
}

.bottomMargin {
    margin-bottom: 1rem;
}

.checkGreen {
    color: $green-check;
}

.newAdmissionError {
    color: $error-color;
    font-weight: 500;
    font-size: 0.75rem;
    align-self: flex-start;
    margin-left: 14px;
}

.headerTitle {
    font-size: 36px;
    color: $text-color;
    align-self: center; 
}

.mobileHeaderContainer {
    position: sticky;
    top: 0;
    background: $text-color;
    flex-direction: row;
    align-items: center;
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    z-index: 9999;
    max-height: 3rem;
}

.mobileHeaderPageTitle {
    font-size: 16px;
    color: $white;
    font-weight: 700;
}

.mobileHeaderPageSemester {
    font-size: 12px;
    color: $white;
}

.mobileHeaderMenu {
    border-radius: 5px;
    margin-top: 1rem;
}

.mobileHeaderProfileIcon {
    align-items: flex-end; 
    justify-content: flex-end;
    margin-left: 1rem;
}

.wide {
    width: 100%;
}

.divider {
    border: 1px solid $divider-color;
}

.headerUserModule {
    display: inline-flex;
    flex-direction: row;
    border: 1px solid $divider-color;
    border-radius: 10px !important;
    background-color: $white;
    min-height: 5rem;
    align-self: flex-end;
    min-width: 12rem;
    margin-bottom: 2rem;
}

.headerUserModulePadding {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
}

.headerUserProfileIcon {
    margin-right: 0.5rem;
    align-self: flex-start;
}

.headerClosed {
    font-size: 36px;
    color: $text-color;
    align-self: center 
}

.basicDataErrorText {
    color: $error-color;
    font-size: 0.75rem;
    margin-top: 4px;
    margin-left: 6px;
}

.basicDataTitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 1rem;
    font-family: 'Roboto', sans-serif !important;
}

.basicDataTitleContainerMobile {
    background: $white;
    padding-right: 0.5rem;
    margin-top: 2rem;
    border-radius: 5px;
    border: 1px solid $divider-color;
}

.basicDataTitleMobile {
    font-size: 16px;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    color: $dark-text;
}

.basicdataSubmitButtonContainter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
}

.basicDataCancelButton {
    background: $text-color;
    border-radius: 0px;
    margin-right: 0.5rem;
}

.basicDataAddress {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: $dark-text;
}

.selectedProgramsTitle {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 1rem;
}

.profileContainer {
    justify-content: space-evenly;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
}

.profileClickable {
    color: $text-color;
    cursor: pointer;
}