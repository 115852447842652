@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*:not(.MuiIconButton-root, .MuiChip-root, .MuiAvatar-root, .MuiBadge-badge, .borderRadius, .borderRadius *) {
    border-radius: 0 !important;
    font-family: 'Roboto', sans-serif !important;
}

*.MuiIconButton-root {
    font-size: 16px !important;
}

main {
    padding-top: 15px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

[class*='Popper'] {
    z-index: 2000;
}

/*
@media screen and (min-height: 700px) {
    .ck-editor__editable {
        max-height: 80vh;
        height: 55vh;
    }
}
*/

a .MuiMenuItem-root .MuiTypography-root,
a .MuiMenuItem-root .MuiListItemIcon-root,
a .MuiMenuItem-root .MuiSvgIcon-root {
    color: #364354;
}

a .MuiMenuItem-root[class*='selected'] .MuiTypography-root,
a .MuiMenuItem-root[class*='selected'] .MuiListItemIcon-root,
a .MuiMenuItem-root[class*='selected'] .MuiSvgIcon-root {
    color: #fff;
}
.MuiTabs-flexContainer .MuiButtonBase-root:not(.studentProfile) {
    background-color: #364354;
}

.MuiTabs-indicator:not(.studentProfile) {
    background-color: #f3ca12 !important;
}

.MuiTabs-flexContainer .MuiButtonBase-root[class*='selected']:not(.studentProfile) {
    color: #f3ca12;
}

::-moz-selection {
    background: #f3ca12;
    color: #fff;
}
::selection {
    background: #f3ca12;
    color: #fff;
}

h2[class*='MuiDialogTitle-root']:not(.studentProfile):not(.eventPresence) {
    background-color: #364354 !important;
    color: #f3ca12;
}

header.MuiPaper-root .MuiTabs-root:not(.studentProfile) .MuiButtonBase-root {
    color: #fff !important;
}
header.MuiPaper-root .MuiTabs-root .MuiButtonBase-root.Mui-disabled {
    color: rgb(204, 204, 204) !important;
}

.accessManagement.green {
    background-color: rgba(157, 255, 118, 0.49);
}

fieldset > div > *:not(legend) {
    margin-top: 0;
}

.MuiPaper-root.MuiMenu-paper {
    max-height: 300px;
}