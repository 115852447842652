.react-calendar {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
}

abbr {
    font-size: 20px;
}

.react-calendar__tile--active {
    background-color: #006edc99;
}
